<template>
  <minimal-bg-header-layout>
    <div>
      <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-1/3">
        <div class="flex flex-col px-6 py-8 border border-gray-100 shadow-2xl rounded bg-white lg:px-12">
          <login-form />
        </div>
        <div class="p-2 w-full py-6 mt-12 border-t border-gray-200 text-center">
          <div class="inline-flex space-x-4">
            <a
              class="text-gray-500 hover:text-purple-500"
              @click="$router.push({ path: '/' })">
              Contacto
            </a>
          </div>
        </div>
      </div>
    </div>
  </minimal-bg-header-layout>
</template>
<script>
import MinimalBgHeaderLayout from '@/components/layouts/MinimalBgHeaderLayout';
import LoginForm from '@/views/public/login/LoginForm.vue';

export default {
  name: 'LoginIndex',

  components: {
    MinimalBgHeaderLayout,
    LoginForm
  }
}
</script>
